import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./Styles";
import image1 from "../../../Assets/images/LandingPage/Section/hand.png";
import image2 from "../../../Assets/images/LandingPage/Section/finger.png";
import image3 from "../../../Assets/images/LandingPage/Section/card.png";
import SectionText from "./SectionText";
import MobileSectionText from "./MobileSectionText";
import gsap from "gsap";

const Section = () => {
  const classes = useStyles();
  const imageRef = useRef(null);
  const scrolldata = [
    {
      img: image1,
      title: "Learn from experienced Industry Leaders",
      subtitle:
        "Get access to the vast expertise of seasoned professionals. Benefit from their insights, knowledge, and practical wisdom gained from years of navigating the professional world. Whether it's career advice, industry secrets, or real-world strategies, our community of seasoned experts is here to guide you toward success. So, tap into their valuable experiences and let their stories and expertise be your stepping stones toward your own achievements.",
      list: [
        "Learn from the experience without going through one with Glocoaches",
      ],
      buttontext: "Explore Opportunities",
    },
    {
      img: image2,
      title: "Leverage Peer Network",
      subtitle:
        "Glosity also helps early career professionals to go beyond their immediate friend circle to interact with people who are skilled or share similar career aspirations or interests. It provides them with a platform to collaborate with peers on projects amplifying their collective efforts for effective outcomes.",
      list: ["Grow and excel with your peers"],
      buttontext: "Explore Opportunities",
    },
    {
      img: image3,
      title: "Showcase Your Skills",
      subtitle:
        "It's not just about listing your qualifications; it's about proving what you can actually do. Your uploaded projects, accomplishments, and experiences form your Proof of Work, highlighting your expertise to potential employers. This unique approach shifts the focus from traditional resumes to tangible demonstrations of your capabilities; Illuminate your potential and secure your dream job by displaying the breadth and depth of your skillset. Our platform connects the space between skill and opportunity, providing a seamless transition from showcasing your talents to landing your dream role.",
      list: [
        "Stand out in an ever-evolving job market through differentiated Glofolio",
      ],
      buttontext: "Explore Opportunities",
    },
  ];
  const [currentImg, setCurrentImg] = useState(0);
  const tl = gsap.timeline();
  useEffect(() => {
    tl.from(imageRef.current, { opacity: 0, duration: 1 }).to(
      imageRef.current,
      {
        opacity: 1,
        duration: 1,
      }
    );
  }, [currentImg]);
  return (
    <div className={classes.section}>
      <div className={classes.imageWrapper} ref={imageRef}>
        <img
          src={scrolldata[currentImg].img}
          className={classes.image}
          key={scrolldata[currentImg].img}
        />
      </div>
      <div className={classes.detailsWrapper}>
        {scrolldata.map((item, id) => (
          <div key={id} className={classes.scrollFullScreen}>
            <SectionText
              key={id}
              item={item}
              id={id}
              setCurrentImg={setCurrentImg}
            />
          </div>
        ))}
      </div>
      <div className={classes.mobileWrapper}>
        {scrolldata.map((item, id) => (
          <MobileSectionText key={id} item={item} id={id} />
        ))}
      </div>
    </div>
  );
};

export default Section;
