import React from "react";
import { theme } from "../../../Utils/breakpoints";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { pxToMvh, pxToMvw, pxToVh } from "../../../Utils/pxToRem";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";

const openedMixin = (theme) => ({
  width: "80%",
  marginTop: pxToVh(80),
  backgroundColor: "#131313",
  overflowX: "hidden !important",
  overflowY: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.ease,
    duration: "1200ms",
  }),
});

const closedMixin = (theme) => ({
  width: "0%",
  marginTop: pxToVh(80),
  backgroundColor: "#131313",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.ease,
    duration: "1200ms",
  }),
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: "80%",
  position: "relative",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const useStyles = makeStyles({
  options: {
    display: "flex",
    flexDirection: "column",
    gap: pxToMvh(20),
    paddingTop: pxToMvh(25),
    paddingLeft: pxToMvw(30),
  },
  text: {
    display: "flex",
    alignItems: "center",
    borderRadius: pxToMvw(45),
    width: pxToMvw(143),
    height: pxToMvh(40),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToMvw(14),
    color: "white",
    backgroundColor: "#131313",
    outline: "none",
    border: "none",
    paddingLeft: pxToMvw(20),
    "&:hover": {
      color: "#0EC99E",
    },
  },
  button: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "block",
      borderRadius: pxToMvw(45),
      outline: "none",
      border: "none",
      fontFamily: "'Poppins', sans-serif;",
      fontWeight: "400",
      fontSize: pxToMvw(14),
      background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%)",
      webkitBackgroundClip: "text",
      webkitTextFillColor: "transparent",
      cursor: "pointer",
      width: pxToMvw(143),
      height: pxToMvh(40),
    },
  },
});
function MiniDrawer({ open }) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Drawer variant="permanent" open={open} anchor="right">
      <List>
        <div className={classes.options}>
          {/* <button className={classes.text}>How it works</button>
          <button className={classes.text}>Showcase</button>
          <button className={classes.text}>Hire</button>
          <button
            className={classes.text}
            onClick={() => {
              navigate("/studentlogin");
            }}
          >
            Login
          </button> */}
          <a
            target="_blank"
            rel="noreferrer"
            href="glosity-ecp://opportunity/65b76325a9f6475819183c13"
            className="no-underline"
          >
            <button className={classes.button}>Recruiters</button>
          </a>
        </div>
      </List>
    </Drawer>
  );
}

export default MiniDrawer;
