import React, { useEffect, useLayoutEffect, useState } from "react";
import { pxToRem } from "../../../Utils/pxToRem";
import { ReactComponent as CloseIcon } from "../../../Assets/images/ExploreCareerPage/ExploreCareerModal/closeIcon.svg";
import { useStyles } from "./Styles";
import CareersQuestions from "./CareersQuestions/CareersQuestions";
import ExpertDetails from "./ExpertDetails/ExpertDetails";
import Reels from "./Reels/Reels";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  closeModal,
  openModal,
  openNestedModal,
} from "../../../redux/Actions/Modal/modal";
import { axiosClient } from "../../../Services/apiClient";
import Modal from "@mui/material/Modal";

const ReelsModal = ({ open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openShareModal, setOpenShareModa] = useState(true);
  const modalState = useSelector((s) => s.modal.modal);
  const [close, setClose] = useState(false);
  const modalData = useSelector((state) => state.modal.data);
  const [faqData, setFaqData] = useState([]);
  const [selectFaq, setSelectFaq] = useState(0);

  const student = useSelector((state) => state.auth.user);
  const studentId = student?.StudentGeneralInfo?._id;

  const handleModalClose = () => {
    setClose(false);
    dispatch(closeModal({ type: "REELSMODAL", close: close }));
  };

  const getFaqData = async () => {
    const response = await axiosClient.get("/getExpertFAQ", {
      params: {
        faq_id: modalData.experts_faq[0],
        expert_name: modalData.name,
      },
    });
    setFaqData(response.data.data.faq_obj[0].faq);
  };

  useLayoutEffect(() => {
    getFaqData();
  }, [modalState]);

  useLayoutEffect(() => {
    const viewHandler = async () => {
      const response = await axiosClient.patch("/updateExpertsViewCount", {
        faq_id: modalData?.experts_faq[0],
        expert_name: modalData?.name,
        question_id: faqData[selectFaq]?._id,
        student_id: studentId,
      });
    };

    viewHandler();
    getFaqData();
  }, [selectFaq]);

  const handelSelectFaq = (index) => {
    setSelectFaq(index);
  };

  const likeHandler = async () => {
    await axiosClient.patch("/updateExpertsLikeCount", {
      faq_id: modalData.experts_faq[0],
      expert_name: modalData.name,
      question_id: faqData[selectFaq]._id,
      student_id: studentId,
    });
  };

  const shareHandler = async () => {
    // const response = await axiosClient.patch("/updateExpertsShareCount", {
    //   faq_id: modalData.experts_faq[0],
    //   expert_name: modalData.name,
    //   question_id: faqData[selectFaq]._id,
    // });
    // console.log(response);
    setOpenShareModa(true);
    dispatch(openNestedModal({ type: "SHAREMODAL", open: openShareModal }));
  };

  // const [viewCount, setViewCount] = useState(0);
  // const [likeCount, setLikeCount] = useState(0);

  return (
    <Modal open={open} className={classes.modalWrapper}>
      <div className={classes.modalContent}>
        <button className={classes.modalCloseButton} onClick={handleModalClose}>
          <CloseIcon className={classes.closeIcon} />
        </button>
        <div className={classes.modalHeader}>
          <h1 className={classes.modalHeaderTitle}>
            Know more about {modalData?.career_name}
          </h1>
          <button
            className={classes.modalButton}
            onClick={() => {
              navigate(`/roadmap/${modalData?.career_name}`);
              setClose(false);
              dispatch(closeModal({ type: "REELSMODAL", close: close }));
            }}
          >
            Go To Roadmap
          </button>
        </div>
        <div className={classes.modalBody}>
          <div className={classes.modalBodyContent}>
            <ExpertDetails
              expertDesignation={modalData?.designation}
              companyName={modalData?.company_name}
              expertName={modalData?.name}
              expertImage={modalData?.experts_image}
              socialMedia={modalData?.social_media_handeler}
            />
            <CareersQuestions
              faqData={faqData}
              selectFaq={handelSelectFaq}
              selectedFaq={selectFaq}
            />
          </div>
          {faqData && faqData.length !== 0 && (
            <Reels
              onLike={likeHandler}
              onShare={shareHandler}
              videoData={faqData[selectFaq]}
              selectFaq={handelSelectFaq}
              selectedFaq={selectFaq}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReelsModal;
