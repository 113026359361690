import React from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./Styles";

const Auth = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <p className={classes.name}>Download the Glosity app to get started!</p>
      <div className={classes.auth}>
        {/* <div className={classes.inputWrapper}>
        <input
          className={classes.input}
          placeholder="yesyoucandoit@iamproudofyou.com"
        />
        <button className={classes.button}>Get Started</button>
      </div> */}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.glosity&pli=1"
          className="no-underline"
        >
          <button className={classes.button}>Play Store</button>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/in/app/glosity/id1668548986"
          className="no-underline"
        >
          <button className={classes.button}>App Store</button>
        </a>
      </div>
    </>
  );
};

export default Auth;
