import { makeStyles } from "@mui/styles";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";
export const useStyles = makeStyles({
  reviewMain: {
    display: "flex",
    flexDirection: "column",
    paddingTop: pxToVh(150),
    paddingBottom: pxToVh(200),
    gap: pxToVw(80),
    position: "relative",
    backgroundColor: "#131313",
    background:
      "radial-gradient(25% 50% at 0% 70%, rgba(14, 201, 158, 0.15) 0%, rgba(196, 196, 196, 0) 100%)",
    [theme.breakpoints.down("mb")]: {
      width: "100vw",
      paddingTop: pxToMvh(100),
      paddingBottom: pxToMvh(0),
      gap: pxToMvw(0),
      backgroundColor: "#131313",
      background:
        "radial-gradient(50% 50% at 0% 100%, rgba(14, 201, 158, 0.15) 0%, rgba(196, 196, 196, 0) 100%)",
    },
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    paddingLeft: pxToVw(600),
    paddingRight: pxToVw(600),
    [theme.breakpoints.down("mb")]: {
      paddingLeft: pxToMvw(80),
      paddingRight: pxToMvw(80),
    },
  },
  header: {
    width: "100%",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(60),
    lineHeight: pxToVw(59),
    background: "-webkit-linear-gradient(0deg,#AFE1D6 ,#009270)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    zIndex: "1",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      textAlign: "center",
      paddingTop: pxToMvh(),
      fontSize: pxToMvw(28),
      lineHeight: pxToMvw(28),
    },
  },
  quote: {
    width: pxToVw(175.23),
    height: pxToVw(149),
    position: "absolute",
    right: pxToVw(480),
    top: pxToVh(300),
    zIndex: "0",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(121),
      height: pxToMvw(103),
      right: pxToMvw(20),
      top: pxToMvh(60),
    },
  },
  reviewCarousalDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: pxToVh(50),
    width: "100vw",
    color: "white",
    [theme.breakpoints.down("mb")]: {
      // display: "none",
      paddingTop: pxToMvh(50),
    },
  },
  carousalDesktop: {
    width: "100%",
  },
  reviewCarousal: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "flex",
      width: "100%",
      paddingTop: pxToMvh(20),
      paddingBottom: pxToMvh(20),
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      gap: pxToMvw(26),
    },
  },
  carousal: {
    width: "100%",
  },
  reviewWrapper: {
    display: "flex",
    paddingTop: pxToVh(20),
    paddingBottom: pxToVh(20),
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    gap: pxToVw(26),

    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  review: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: pxToVh(40),
    gap: pxToVw(13),
    zIndex: "2",
  },
  reviewComponent: {
    display: "none",
  },
  textarea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(753),
    height: pxToVh(230),
    gap: pxToVw(12),
    backgroundColor: "#222222",
    borderRadius: pxToVw(20),
    paddingLeft: pxToVw(42),
    paddingRight: pxToVw(42),
    [theme.breakpoints.down("mb")]: {
      paddingLeft: pxToMvw(42),
      paddingRight: pxToMvw(42),
      gap: pxToMvw(20),
      borderRadius: pxToMvw(20),
      width: pxToMvw(410),
      height: pxToMvh(310),
    },
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    fontSize: pxToVw(18),
    lineHeight: pxToVw(28),
    color: "#9D9D9D",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(25),
      lineHeight: pxToMvw(28),
    },
  },
  reviewText: {
    width: "100%",
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(20),
    lineHeight: pxToVw(24),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(22),
      lineHeight: pxToMvw(30),
    },
  },
  personalInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(300),
    height: pxToVh(90),
    gap: pxToVw(18),
    padding: pxToVw(10),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(300),
      height: pxToMvh(90),
      gap: pxToMvw(18),
      padding: pxToMvw(10),
    },
  },
  imageWrapper: {
    width: pxToVw(56),
    height: pxToVw(56),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(56),
      height: pxToMvw(56),
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  name: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(18),
    lineHeight: pxToVw(28),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(18),
      lineHeight: pxToMvh(28),
    },
  },
  designation: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    lineHeight: pxToVw(24),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(14),
      lineHeight: pxToMvw(24),
    },
  },
  arrowRight: {
    position: "absolute",
    zIndex: 2,
    top: 70,
    width: pxToVw(50) + "!important",
    height: pxToVh(50) + "!important",
    right: 100,
    cursor: "pointer",
  },
  arrowLeft: {
    position: "absolute",
    zIndex: 2,
    top: 70,
    width: pxToVw(50) + "!important",
    height: pxToVh(50) + "!important",
    left: 100,
    cursor: "pointer",
  },
  dots: {
    width: pxToVw(10),
    height: pxToVw(10),
    display: "inline-block",
    margin: "0 8px",
    borderRadius: "50%",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(10),
      height: pxToMvw(10),
    },
  },
});
