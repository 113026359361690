import React from "react";
import { useStyles } from "./Styles";
import { ReactComponent as Logo } from "../../../Assets/images/Footer/logo.svg";
import { ReactComponent as Insta } from "../../../Assets/images/Footer/insta.svg";
import { ReactComponent as Ball } from "../../../Assets/images/Footer/ball.svg";
import { ReactComponent as Twitter } from "../../../Assets/images/Footer/twitter.svg";
import { ReactComponent as Youtube } from "../../../Assets/images/Footer/youtube.svg";
import { ReactComponent as Message } from "../../../Assets/images/Footer/message.svg";
import { ReactComponent as Mobile } from "../../../Assets/images/Footer/mobile.svg";
import { ReactComponent as Location } from "../../../Assets/images/Footer/location.svg";
import bottomlogo from "../../../Assets/images/Footer/logo.png";

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.left}>
        <Logo className={classes.logo} />
        <div className={classes.subtitleWrapper}>
          <p className={classes.subtitle}>Copyright © 2023 Glosity.club</p>
          <p className={classes.subtitle}>All rights reserved</p>
        </div>
        <div className={classes.icons}>
          <div className={classes.iconWrapper}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/glosity_club/"
            >
              <Insta />
            </a>
          </div>
          {/* <div className={classes.iconWrapper}>
            <Ball />
          </div> */}
          <div className={classes.iconWrapper}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/glosity_club"
            >
              <Twitter />
            </a>
          </div>
          <div className={classes.iconWrapper}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UC6uqnlkNNpOAqRZTxaDPHTQ"
            >
              <Youtube />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.right}>
        {/* <div className={classes.list}>
          <h4 className={classes.title}>Glosity</h4>
          <p className={classes.subtitle}>About us</p>
          <p className={classes.subtitle}>Blog</p>
          <p className={classes.subtitle}>Contact us</p>
          <p className={classes.subtitle}>Pricing</p>
          <p className={classes.subtitle}>Testimonials</p>
        </div> */}
        <div className={classes.list}>
          <h4 className={classes.title}>Reach Us</h4>
          <div className={classes.listGroup}>
            <div className={classes.listIconWrapper}>
              <Message />
            </div>
            <p className={classes.subtitle}>contact@glosity.club</p>
          </div>
          {/* <div className={classes.listGroup}>
            <div className={classes.listIconWrapper}>
              <Mobile />
            </div>
            <p className={classes.subtitle}>+91 </p>
          </div> */}
          <div className={classes.listGroup}>
            <div className={classes.listIconWrapper}>
              <Location />
            </div>
            <p className={classes.subtitle}> Bhubaneswar, Odisha</p>
          </div>
        </div>
      </div>

      <img src={bottomlogo} className={classes.bottomlogo} />
    </div>
  );
};

export default Footer;
