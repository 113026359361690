import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import { ReactComponent as CloseIcon } from "../../../Assets/images/Roadmap/cross.svg";
import { ReactComponent as FileIcon } from "../../../Assets/images/Roadmap/fileIcon.svg";
import { ReactComponent as VideoIcon } from "../../../Assets/images/Roadmap/videoIcon.svg";
import { ReactComponent as CompletedIcon } from "../../../Assets/images/Roadmap/completedIcon.svg";
import { ReactComponent as CompletedIconV2 } from "../../../Assets/images/Roadmap/completedIcon2.svg";
import { openModal } from "../../../redux/Actions/Modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux/Actions/Modal/modal";
import Modal from "@mui/material/Modal";
import { axiosClient } from "../../../Services/apiClient";
import { setData } from "../../../redux/Actions/RoadMap";

export const RoadmapModal = ({ open }) => {
  const [close, setClose] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.RoadMap.modalData.data);
  const subPath_id = useSelector((state) => state.RoadMap.modalData.subPath_id);
  const topic_id = modalData._id;
  const learning_path_id = useSelector(
    (state) => state.RoadMap.modalData.learning_path_id
  );
  const user = useSelector((s) => s.auth.user);
  const userId = user.StudentGeneralInfo._id;

  const classes = useStyles({ disabled });

  useEffect(() => {
    if (modalData.isAllResourceCompleted.includes(userId)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [modalData]);

  const handleComplete = async (id) => {
    try {
      const res = await axiosClient.post(
        `/oneResourceCompletedForLearningPath`,
        {
          learning_path_id: learning_path_id,
          subPath_id: subPath_id,
          topic_id: topic_id,
          resources_id: id,
          student_id: userId,
        }
      );
      const data = res.data.topic;
      const newData = { data, learning_path_id, subPath_id };
      dispatch(setData(newData));
    } catch (err) {
      console.log(err);
    }
  };

  const handleTopicComplete = async () => {
    try {
      const res = await axiosClient.post(`/markAsCompleteForLearningPath`, {
        learning_path_id: learning_path_id,
        subPath_id: subPath_id,
        topic_id: topic_id,
        student_id: userId,
      });
      const data = res.data.topic;
      const newData = { data, learning_path_id, subPath_id };
      dispatch(setData(newData));
    } catch (err) {
      console.log(err);
    }
  };

  const handleModalOpen = () => {
    setClose(false);
    dispatch(closeModal({ type: "ROADMAPMODAL", close: close }));
  };
  return (
    <Modal open={open} className={classes.modalWrapper}>
      <div className={classes.modalContent}>
        <CloseIcon className={classes.closeIcon} onClick={handleModalOpen} />
        <div className={classes.modalBody}>
          <h1 className={classes.modalHeaderTitle}>{modalData.topicName}</h1>
          <p className={classes.modalDescription}>{modalData.description}</p>
          <p className={classes.modalResourceToFollow}>Resources to follow</p>
          <div className={classes.modalResourcesDiv}>
            {modalData.resources.map((resource, index) => {
              if (resource.resourceType === "txt") {
                return (
                  <div
                    className={classes.modalResource}
                    key={index}
                    onClick={() => {
                      handleComplete(resource._id);
                    }}
                  >
                    <FileIcon
                      className={`${
                        resource.isResourceCompleted.includes(userId)
                          ? classes.ResourceIconOpened
                          : classes.ResourceIcon
                      }`}
                    />
                    <p
                      className={`${
                        resource.isResourceCompleted.includes(userId)
                          ? classes.modalResourcesNameOpened
                          : classes.modalResourcesName
                      }`}
                    >
                      {resource.resourceName}
                    </p>
                  </div>
                );
              } else if (resource.resourceType === "video") {
                return (
                  <div
                    className={classes.modalResource}
                    key={index}
                    onClick={() => {
                      handleComplete(resource._id);
                    }}
                  >
                    <VideoIcon
                      className={`${
                        resource.isResourceCompleted.includes(userId)
                          ? classes.ResourceIconOpened
                          : classes.ResourceIcon
                      }`}
                    />
                    <p
                      className={`${
                        resource.isResourceCompleted.includes(userId)
                          ? classes.modalResourcesNameOpened
                          : classes.modalResourcesName
                      }`}
                    >
                      {resource.resourceName}
                    </p>
                  </div>
                );
              }
            })}

            {/* <div className={classes.modalResource}>
              <FileIcon className={classes.ResourceIconOpened} />
              <p className={classes.modalResourcesNameOpened}>
                How to build a career in Data Science?
              </p>
            </div>
            <div className={classes.modalResource}>
              <VideoIcon className={classes.ResourceIcon} />
              <p className={classes.modalResourcesName}>
                Who are Data Sciencetist?
              </p>
            </div> */}
          </div>
          {disabled ? (
            <button className={classes.modalMarkAsCompleted}>
              <CompletedIconV2 className={classes.CompletedIcon} />
              Completed
            </button>
          ) : (
            <button
              className={classes.modalMarkAsCompleted}
              onClick={handleTopicComplete}
            >
              <CompletedIcon className={classes.CompletedIcon} />
              Mark as Completed
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
