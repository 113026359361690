import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const LinkShare = () => {
  const params = useParams();
  const [platform, setPlatform] = useState("");
  const shareableBaseUrl = "glosity-ecp://opportunity/";
  const link = shareableBaseUrl + params?.id;
  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.glosity";
  const appleStoreLink =
    "https://apps.apple.com/in/app/glosity/id1668548986";

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("android") > -1) {
      setPlatform("Android");
    }
    if (/(ipad|iphone|ipod)/g.test(userAgent)) {
      setPlatform("Apple");
    }
  }, []);

  useEffect(() => {
    window.location.href = link;
    const timeoutId = setTimeout(() => {
        if (platform === "Android") {
            window.location.href =  playStoreLink;
          } else {
            window.location.href = appleStoreLink;
          }
      }, 2000);
      return () => clearTimeout(timeoutId);
    }, [platform]);


  return (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={link}
        className="no-underline"
      ></a>
    </div>
  );
};

export default LinkShare;
