import React, { useEffect } from "react";
import { useStyles } from "./Styles";
import Topbar from "./Topbar/Topbar";
import Header from "./Header";
import Footer from "../Shared/Footer/Footer";
import Section from "./Section/";
import ReviewMain from "./Reviews";
import Subscribe from "./Subscribe";
import CompanySection from "./CompanySection";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

const LandingPage = ({ auth }) => {
  if (auth) {
    return <Navigate to="/explorecareer" />;
  }
  const classes = useStyles();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <div className={classes.landingPage}>
      <Topbar />
      <Header />
      <CompanySection />
      <Section />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default LandingPage;
