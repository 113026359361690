import React from "react";
import { useStyles } from "./Styles";

const Review = ({ title, review, image, name, designation }) => {
  const classes = useStyles();
  return (
    <div className={classes.review}>
      <div className={classes.textarea}>
        <p className={classes.title}>{title}</p>
        <p className={classes.reviewText}>{review}</p>
      </div>
      <div className={classes.personalInfo}>
        {/* <div className={classes.imageWrapper}>
          <img src={image} className={classes.image} />
        </div> */}
        <div>
          <p className={classes.name}>{name}</p>
          <p className={classes.designation}>{designation}</p>
        </div>
      </div>
    </div>
  );
};

export default Review;
