import { takeEvery, put, call } from "redux-saga/effects";
import * as types from "../../types";
import { SaveGeneralInfo } from "../../../Services/lib/RecruiterOnboarding";
import {
  fetchCompanyType,
  fetchCompanySize,
  getIndustryApi,
  getDomain,
  getCompanyNames,
  addCompanyApi,
} from "../../../Services/lib/RecruiterOnboarding";

// async function saveGeneral(formData) {
//    const response = await SaveGeneralInfo(formData);

//    return response.data;

// }

function* setGeneralFormData(action) {
  let formData = {
    name: action.generalInfo.name,
    companyEmailId: action.generalInfo.email,
    password: action.generalInfo.password,
    companyName: action.generalInfo.cname,
    sendCount: 0,
  };
  let res = SaveGeneralInfo(formData);
  if (res.status == 200) {
    yield put({
      type: types.SET_GENERAL_SUCCESS,
      generalInfo: action.generalInfo,
    });
  } else {
    yield put({ type: types.SET_GENERAL_FAIL, message: "Failed" });
  }
}

// function* setCompanyForm(action) {

//    let {res} = yield call(saveCompanyPage(action));
//    if (res.status == 200) {

//       yield put({type: types.SET_COMPANY_SUCCESS, generalInfo: action.generalInfo});

//    } else {
//       yield put({type: types.SET_COMPANY_FAIL, message:"Failed"});
//       }

// }

function* getAsyncDomain() {
  const { res } = yield call(getDomain);
  if (res.data.success) {
    yield put({ type: types.GET_DOMAIN_SUCCESS, domains: res.data.data });
  } else {
    yield put({ type: types.GET_DOMAIN_FAIL });
  }
}

function* addCompany() {
  const { res } = yield call(addCompanyApi);
  if (res.data.success) {
    yield put({
      type: types.ADD_COMPANY_SUCCESS,
      // companyName: action.companyName,
      companyName: res.data.data.companyName,
    });
  } else {
    yield put({ type: types.ADD_COMPANY_FAIL });
  }
}

function* setCompanyType() {
  const { res } = yield call(fetchCompanyType);
  if (res.data.success) {
    yield put({
      type: types.SET_COMPANY_TYPE_SUCCESS,
      companyTypes: res.data.data,
    });
  } else {
    yield put({ type: types.SET_COMPANY_TYPE_FAIL });
  }
}

function* setCompanySize() {
  const { res } = yield call(fetchCompanySize);
  if (res.data.success) {
    yield put({
      type: types.SET_COMPANY_SIZE_SUCCESS,
      companySize: res.data.data,
    });
  } else {
    yield put({ type: types.SET_COMPANY_SIZE_FAIL });
  }
}

function* getCompany() {
  const { res } = yield call(getCompanyNames);
  if (res.data.success) {
    yield put({ type: types.GET_COMPANY_SUCCESS, companyNames: res.data.data });
  } else {
    yield put({ type: types.GET_COMPANY_FAIL });
  }
}

function* getIndustry() {
  const { res } = yield call(getIndustryApi);
  if (res.data.success) {
    yield put({ type: types.GET_INDUSTRY_SUCCESS, industries: res.data.data });
  } else {
    yield put({ type: types.GET_INDUSTRY_FAIL });
  }
}

export function* watcherGeneralForm() {
  yield takeEvery(types.SET_GENERAL_REQUESTED, setGeneralFormData);
}

export function* watcherGetCompanyType() {
  yield takeEvery(types.SET_COMPANY_TYPE_REQUESTED, setCompanyType);
}

export function* watcherGetCompanyName() {
  yield takeEvery(types.GET_COMPANY_REQUESTED, getCompany);
}

export function* watcherGetindustry() {
  yield takeEvery(types.GET_COMPANY_REQUESTED, getIndustry);
}

export function* watchersetCompanySize() {
  yield takeEvery(types.SET_COMPANY_SIZE_REQUESTED, setCompanySize);
}

export function* watcherGetAddCompany() {
  yield takeEvery(types.ADD_COMPANY_SUCCESS, addCompany);
}

// export function* watcherCompanyPage() {
//    yield takeEvery(types.SET_COMPANY_REQUESTED, setCompanyForm);
// }

export function* watcherDomainSelect() {
  yield takeEvery(types.GET_DOMAIN_REQUESTED, getAsyncDomain);
}
