import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw, pxToMvw, pxToMvh } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  //index file
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    width: "100vw",
    height: pxToVh(1080),
    backgroundColor: "#131313",
    paddingBottom: pxToVw(100),
    background:
      "radial-gradient(50% 50% at 20% 3%, rgba(14, 201, 158, 0.15) 0%, rgba(196, 196, 196, 0) 100%)",
    [theme.breakpoints.down("mb")]: {
      flexDirection: "column",
      width: "100vw",
      height: "fit-content",
      paddingTop: pxToMvh(50),
      paddingBottom: pxToMvh(100),
      gap: pxToMvh(70),
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    gap: pxToVw(40),
    paddingTop: pxToVh(200),
    paddingLeft: pxToVw(190),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      paddingTop: pxToVh(0),
      gap: pxToMvw(50),
      paddingLeft: pxToMvw(24),
    },
  },
  right: {
    display: "flex",
    gap: pxToVw(20),
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      position: "relative",
      paddingRight: pxToVw(0),
    },
  },
  //title file
  title: {
    display: "flex",
    flexDirection: "column",
    width: pxToVw(785),
    [theme.breakpoints.down("mb")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  },
  text: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(90),
    lineHeight: pxToVw(80),
    background: "-webkit-linear-gradient(0deg,#AFE1D6 ,#009270)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    paddingBottom: pxToVh(17),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(55),
      lineHeight: pxToMvh(55),
    },
  },
  subtext: {
    width: pxToVw(540),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    color: "#9D9D9D",
    lineHeight: pxToVw(32),
    paddingTop: pxToVh(24),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      fontSize: pxToMvw(18),
      paddingRight: pxToMvh(35),
      lineHeight: pxToMvh(25),
    },
  },
  star: {
    width: pxToMvw(135),
    height: pxToMvw(135),
  },
  rectangle: {
    width: pxToMvw(135),
    height: pxToMvw(135),
  },
  polygon: {
    width: pxToMvw(135),
    height: pxToMvw(135),
  },
  heroMobile: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      gap: pxToMvw(30),
      paddingLeft: pxToMvh(15),
      paddingRight: pxToMvh(15) + "!important",
    },
  },
  heros: {
    display: "flex",
    width: "100%",
    height: "100%",
    gap: pxToVw(25),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  heroWrapper: {
    width: pxToVw(230),
    height: pxToVw(1000),
  },
  heroImageWrapper: {
    width: pxToVw(230),
    height: pxToVh(1000),
  },
  heroImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  carousal: {
    width: "100%",
    height: pxToVh(1050),
  },
  carousalImage: {
    width: pxToVw(120),
    height: pxToVh(1050),
    marginTop: pxToVw(20),
    objectFit: "cover",
    borderRadius: pxToVw(150),
  },
  //auth section
  auth: {
    display: "flex",
    flexDirection: "row",
    gap: pxToVw(20),
    width: pxToVw(785),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      paddingLeft: pxToVw(0),
      gap: pxToMvw(20),
    },
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: pxToVw(41),
    width: pxToVw(620),
    height: pxToVw(80),
    border: "1px solid #222222",
    transition: "all .2s ease",
    borderRadius: pxToVw(40),
    backgroundColor: "#222222",
    "&:focus-within": {
      border: "1px solid #0EC99E",
    },
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  name: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(18),
    color: "white",
    lineHeight: pxToVw(28),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(18),
      lineHeight: pxToMvh(28),
    },
  },
  input: {
    width: pxToVw(420),
    height: "100%",
    backgroundColor: "#222222",
    borderRadius: pxToVw(40),
    border: "none",
    outline: "none",
    color: "#474747",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(200),
    height: pxToVh(75),
    borderRadius: pxToVw(45),
    outline: "none",
    border: "none",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(22),
    background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%)",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(143),
      height: pxToMvh(40),
      borderRadius: pxToMvw(45),
      fontSize: pxToMvw(16),
    },
  },
  googleLogo: {
    width: pxToVw(35),
    height: pxToVw(35),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(23),
      height: pxToMvh(20),
    },
  },
  googleButton: {
    display: "flex",
    width: pxToVw(327),
    height: pxToVw(80),
    alignItems: "center",
    justifyContent: "center",
    gap: pxToVw(10),
    fontFamily: "'Poppins', sans-serif;",
    borderRadius: pxToVw(40),
    backgroundColor: "#222222",
    outline: "none",
    fontSize: pxToVw(20),
    border: "2px solid #0EC99E",
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      borderRadius: pxToMvw(45),
      gap: pxToMvw(5),
      fontSize: pxToMvw(16),
      width: pxToMvw(230),
      height: pxToMvh(45),
    },
  },
  review: {
    width: "100%",
  },
  reviewCarousalDesktop: {
    width: "100%",
  },
  carousalDesktop: {
    width: "100%",
  },
});
