import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Hand } from "../../../Assets/images/LandingPage/Section/hand.svg";
import { useStyles } from "./Styles";

const SectionText = ({ item, id, setCurrentImg }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const setItemVisible = (e) => {
    if (e[0]?.isIntersecting) {
      setIsVisible(!isVisible);
      setCurrentImg(id);
    }
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(setItemVisible, options);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      className={`${classes.notVisible} ${
        isVisible ? `${classes.visible}` : ""
      }`}
      ref={ref}
    >
      <div className={classes.details}>
        <h3 className={classes.title}>{item.title}</h3>
        <p className={classes.subtitle}>{item.subtitle}</p>
        <div className={classes.listWrapper}>
          {item.list.map((l, id) => (
            <div key={id} className={classes.handWrapper}>
              <Hand className={classes.hand} />
              <p className={classes.list}>{l}</p>
            </div>
          ))}
        </div>
        {/* <button className={classes.button}>{item.buttontext}</button> */}
      </div>
    </div>
  );
};

export default SectionText;
