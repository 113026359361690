import {
  Facebook,
  LinkedIn,
  Twitter,
  Telegram,
  Instagram,
  Pinterest,
} from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React from "react";
import { pxToMvw, pxToRem } from "../../../../Utils/pxToRem";
import { useStyles } from "./Styles";
import { theme } from "../../../../Utils/breakpoints";
import { useNavigate } from "react-router-dom";

const ExpertDetails = ({
  expertDesignation,
  expertName,
  expertImage,
  companyName,
  socialMedia,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const socialMediaIcons = {
    Facebook,
    LinkedIn,
    Twitter,
    Telegram,
    Pinterest,
    Instagram,
  };
  return (
    <div className={classes.ExpertDetailsWrapper}>
      <div>
        <Avatar
          src={expertImage}
          sx={{
            height: pxToRem(71),
            width: pxToRem(71),
            [theme.breakpoints.down("sm")]: {
              width: pxToMvw(50),
              height: pxToMvw(50),
            },
          }}
        />
      </div>
      <div className={classes.ExpertDetails}>
        <div className={classes.ExpertName}>{expertName}</div>
        <div className={classes.ExpertDesignation}>
          {expertDesignation}, {companyName}
        </div>
        <div className={classes.ExpertSocial}>
          {socialMedia?.map((social, index) => {
            console.log(social);
            const Icon = socialMediaIcons[social.type];
            return (
              <a href={social.url} target="_blank" rel="noreferrer">
                <Icon
                  key={index}
                  sx={{
                    fontSize: pxToRem(24),
                    marginRight: pxToRem(10),
                    cursor: "pointer",
                  }}
                />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ExpertDetails;
