import { Favorite, Reply, Visibility } from "@mui/icons-material";
import ReactPlayer from "react-player";
import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./Styles";
import { ReactComponent as PlayButton } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/playButton.svg";
import { ReactComponent as Liked } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/likeIconBlue.svg";
import { ReactComponent as PauseButton } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/pauseButton.svg";
import Badge from "@mui/material/Badge";
import { pxToRem } from "../../../../Utils/pxToRem";
import { Slider } from "@mui/material";
import { ReactComponent as VolumeUp } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/volumeUp.svg";
import { ReactComponent as VolumeOff } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/volumeOff.svg";
import { axiosClient } from "../../../../Services/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { Dislike, Like } from "../../../../redux/Actions/ExploreCareer";

const Reels = ({ videoData, selectFaq, selectedFaq, onLike, onShare }) => {
  const videoRef = useRef(null);
  const user = useSelector((s) => s.auth.user);
  const userId = user.StudentGeneralInfo._id;

  const [playerState, setPlayerState] = useState({
    isPlaying: true,
    isMuted: true,
    progress: 0,
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  const handleOnTimeUpdate = () => {
    const progress =
      (videoRef.current.getCurrentTime() / videoRef.current.getDuration()) *
      100;
    setPlayerState({
      ...playerState,
      progress,
    });
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    videoRef.current.seekTo(
      (videoRef.current.getDuration() / 100) * manualChange
    );
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  };

  const format = (seconds) => {
    if (isNaN(seconds)) {
      return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes().toString().padStart(2, "0");
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const currentTime =
    videoRef && videoRef.current ? videoRef.current.getCurrentTime() : "00:00";

  const duration =
    videoRef && videoRef.current ? videoRef.current.getDuration() : "00:00";

  const [viewCount, setViewCount] = useState(0);
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    setIsLiked(videoData.like.like_array.includes(userId));
  }, [videoData]);

  useEffect(() => {
    setLikeCount(videoData.like.like_count);
    setViewCount(videoData.view.view_count);
  }, [videoData]);

  const onLikeHandler = () => {
    onLike();
    setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
    setIsLiked(!isLiked);
  };

  const classes = useStyles({ isLiked });
  return (
    <div className={classes.reelsWrapper}>
      <div className={classes.reels}>
        <div className={classes.reelVideo}>
          <ReactPlayer
            ref={videoRef}
            height="100%"
            width="100%"
            url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
            playing={playerState.isPlaying}
            muted={playerState.isMuted}
            onProgress={handleOnTimeUpdate}
            onEnded={() => {
              // setViewCount(viewCount + 1);
              selectFaq(selectedFaq + 1);
            }}
          />
        </div>
        <div className={classes.control}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FFFFFF",
              fontWeight: "400",
            }}
          >
            <p className={classes.viewCount}>
              <Visibility />
              {viewCount}
            </p>
          </div>
          <div className={classes.videoControls} onClick={togglePlay}>
            {playerState.isPlaying ? (
              <PauseButton style={{ height: pxToRem(40), width: "auto" }} />
            ) : (
              <PlayButton style={{ height: pxToRem(40), width: "auto" }} />
            )}
          </div>
          <div className={classes.videoActivity}>
            <div className={classes.likeVideo} onClick={onLikeHandler}>
              <div className={classes.likeCount}>{likeCount}</div>
              <Favorite className={classes.likeIcon} />
            </div>
            {/* <div className={classes.shareVideo} onClick={onShare}>
              <Reply className={classes.shareIcon} />
            </div> */}
          </div>
          <div className={classes.videoProgress}>
            <div className={classes.progressBar}>
              <Slider
                size="small"
                min={0}
                max={100}
                sx={{
                  color: "#0EC99E",
                  transition: "none",
                  height: 2,
                  "& .MuiSlider-root .MuiSlider-dragging": {
                    boxShadow: "none",
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: "#fff",
                    opacity: 1,
                  },
                  "& .MuiSlider-thumb": {
                    boxShadow: "none",
                    width: "15px",
                    height: "15px",
                    border: "4px solid currentColor",
                    backgroundColor: "#fff",
                    "&:hover, &.Mui-focusVisible, &.Mui-active": {
                      boxShadow: "none",
                    },
                  },
                }}
                value={playerState.progress}
                onChange={handleVideoProgress}
              />
            </div>
            <div className={classes.progress}>
              {format(currentTime)}/{format(duration)}
            </div>
            {playerState.isMuted ? (
              <VolumeOff
                style={{
                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            ) : (
              <VolumeUp
                style={{
                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reels;
