import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw, pxToMvw, pxToMvh } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  topbar: {
    display: "flex",
    justifyContent: "space-between",
    height: pxToVh(80),
    backgroundColor: "#131313",
    paddingLeft: pxToVw(195) + "!important",
    paddingRight: pxToVw(223) + "!important",
    boxShadow: "0px 6px 74px rgba(14, 201, 158, 0.07)",
    [theme.breakpoints.down("mb")]: {
      paddingLeft: pxToMvw(30) + "!important",
      paddingRight: pxToMvw(30) + "!important",
      position: "sticky",
    },
  },
  topbarIcons: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "block",
    },
  },
  drawer: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "block",
    },
  },
  left: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(87),
  },
  buttons: {
    display: "flex",
    gap: pxToVw(42),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  logo: {
    width: pxToVw(146),
    height: pxToVw(51),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(130),
      height: pxToVh(50),
    },
  },
  text: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: pxToVw(100),
    height: pxToVw(40),
    backgroundColor: "#131313",
    color: "white",
    borderRadius: pxToVw(20),
    fontSize: pxToVw(14),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    outline: "none",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0EC99E",
      color: "#131313",
    },
    transition: "all 0.1s ease",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(36),
    },
  },
  dropDown: {
    display: "flex",
    flexDirection: "column",
  },
  mobileOptions: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: ({ open }) => (open ? "flex" : "none"),
      flexDirection: "column",
      height: "100vh",
      gap: pxToVw(150),
      width: "100%",
      backgroundColor: "#131313",
      paddingTop: pxToVw(108),
      paddingLeft: pxToVw(80),
      zIndex: 999,
    },
  },
  options: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(40),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  right: {
    display: "flex",
    alignItems: "center",
  },
  menu: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "flex",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: pxToVw(100),
    height: pxToVw(40),
    backgroundColor: "#0EC99E",
    color: "#131313",
    borderRadius: pxToVw(20),
    fontSize: pxToVw(14),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    outline: "none",
    border: "none",
    cursor: "pointer",
    textTransform: "none",
    [theme.breakpoints.down("mb")]: {
      borderRadius: pxToVw(45),
      fontSize: pxToVw(35),
      width: pxToMvw(123),
      height: pxToMvh(38),
    },
  },
});
