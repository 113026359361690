import "./App.css";
import LandingPage from "./Components/LandingPage";
// import ForgotPass from "./Components/RecruiterOnbordingPage/RecoverPassword";
// import RecoverPass from "./Components/RecruiterOnbordingPage/RecoverPassword/index";
// import ResetPass from "./Components/RecruiterOnbordingPage/ResetPassword";
import { Routes, Route } from "react-router-dom";
// import Login from "./Components/RecruiterOnbordingPage/RecruiterLogin";
// import Onboarding from "./Components/RecruiterOnbordingPage";
// import Profile from "./Components/ProfilePage";
// import StudentLogin from "./Components/StudentOnboarding/StudentLogin";
// import StudentRegister from "./Components/StudentOnboarding/StudentRegister";
// import MultiStepForm from "./Components/StudentOnboarding/MultiStepForm";
// import ExploreCareers from "./Components/ExploreCareersPage";
// import ResetPassword from "./Components/StudentOnboarding/ResetPassword";
// import Roadmap from "./Components/RoadmapPage";
import { useSelector } from "react-redux";
import ModalManager from "./Components/Shared/ModalManager/ModalManager";
import NestedModalManager from "./Components/Shared/NestedModalManager/NestedModalManager";
import ReactGA from "react-ga4";
import { ga4Id } from "./configs/baseUrl";
import { useEffect } from "react";
import LinkShare from "./Components/LinkShare";

function App() {
  const user = useSelector((state) => state.auth.user);
  const loggedIn = user?.StudentGeneralInfo?.isDetailedFilled;
  // const user = true;
  // const loggedIn = true;
  ReactGA.initialize("G-7GTBHDPN3T");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <div className="app">
      <ModalManager />
      <NestedModalManager />
      <Routes>
        <Route path="/" element={<LandingPage auth={loggedIn} />} />
        <Route path="/opportunity/share/:id" element={<LinkShare />} />

        {/* <Route path="login" element={<Login />} />
        <Route path="signup" element={<Onboarding />} />
        <Route path="forgot" element={<RecoverPass />} />
        <Route path="reset" element={<ResetPass />} />
        <Route path="studentlogin" element={<StudentLogin />} />
        <Route path="studentregister" element={<StudentRegister />} />
        <Route
          path="studentDetailsform"
          element={<MultiStepForm auth={user} />}
        />
        <Route path="forgotpassword" element={<ResetPassword />} />
        <Route path="profile" element={<Profile auth={loggedIn} />} />
        <Route
          path="explorecareer"
          element={<ExploreCareers auth={loggedIn} />}
        />
        <Route path="roadmap/:stream" element={<Roadmap auth={loggedIn} />} /> */}
      </Routes>
    </div>
  );
}
export default App;
