import { makeStyles } from "@mui/styles";
import {
  pxToVw,
  pxToVh,
  pxToRem,
  pxToMvw,
  pxToMvh,
} from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  modalWrapper: {
    position: "absolute",
    zIndex: 11,
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(10px)",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFF",
    width: pxToRem(1307),
    height: pxToRem(779),
    overflow: "hidden",
    borderRadius: pxToRem(24),
    padding: pxToVw(30) + " " + pxToVw(60),
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,.2)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(429),
      height: pxToMvh(750),
      paddingTop: pxToMvw(20),
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    gap: pxToVh(80),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: pxToMvh(20),
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  modalHeaderTitle: {
    fontWeight: "700",
    fontSize: pxToRem(40),
    letterSpacing: pxToRem(0.5),
    textTransform: "capitalize",
    color: "#0D1C2E",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(18),
      letterSpacing: pxToRem(0),
    },
  },
  modalButton: {
    borderRadius: pxToVw(12),
    border: "none",
    outline: "none",
    background: "#0EC99E",
    padding: pxToVw(10) + " " + pxToVw(18),
    color: "white",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: pxToVw(16),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(145),
      padding: 0,
      height: pxToMvw(33),
      fontSize: pxToMvw(14),
      borderRadius: pxToMvw(8),
    },
  },
  modalCloseButton: {
    position: "absolute",
    top: pxToVh(15),
    right: pxToVw(15),
    width: pxToVw(40),
    height: pxToVw(40),
    border: "none",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(14, 201, 158, 0.2)",
    borderRadius: "100%",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(30),
      height: pxToMvw(30),
      top: pxToMvh(15),
      right: pxToMvw(15),
    },
  },
  closeIcon: {
    width: pxToVw(15),
    height: pxToVw(15),
    color: "#0EC99E",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(10),
      height: pxToMvw(10),
    },
  },
  modalBody: {
    gap: pxToRem(50),
    display: "flex",
    height: "100%",
    overflow: "hidden",
    margin: pxToRem(20) + " " + 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      overflow: "scroll",
    },
  },

  modalBodyContent: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});
