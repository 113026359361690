import {
  SET_DATA,
  SET_SELECTED_REEL,
  Like,
  Dislike,
} from "../../Actions/ExploreCareer";

const initialState = {
  cardData: null,
  selectedReel: null,
  like: 0,
};

const ExploreCardReducer = (state = initialState, action) => {
  switch (action.type) {
    // case SET_DATA:
    //   return { ...state, cardData: action.payload };
    // case SET_SELECTED_REEL:
    //   return { ...state, selectedReel: action.payload };
    case Like:
      console.log(action.payload);
      return {
        ...state,
        like: state.like + 1,
      };
    case Dislike:
      console.log(action.payload);
      return {
        ...state,
        like: state.like - 1,
      };
    default:
      return state;
  }
};

export default ExploreCardReducer;
