import React from "react";
import { useStyles } from "./Styles";

const Input = () => {
  const classes = useStyles();
  return (
    <div className={classes.mobileinputWrapper}>
      {/* <div className={classes.inputWrapper}> */}
      {/* <input
          className={classes.input}
          placeholder="yesyoucandoit@iamproudofyou.com"
        />
        <button className={classes.button}>Get Started</button>
      </div>
      <button className={classes.mobilebutton}>Get Started</button> */}
      {/* </div> */}
    </div>
  );
};

export default Input;
