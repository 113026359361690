import React, { useEffect, useRef } from "react";
import { useStyles } from "./Styles";
import SectionText from "./SectionText";
import lottie from "lottie-web";

const CompanySection = () => {
  const classes = useStyles();
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://assets8.lottiefiles.com/packages/lf20_vdxojcqp.json",
    });
  }, []);

  return (
    <div className={classes.section}>
      <div className={classes.animationWrapper} ref={container}></div>
      <div className={classes.detailsWrapper}>
        <SectionText
          title="Get opportunities, that propels your passion"
          subtitle="Glosity propels you into the career orbit, through your proof of work that distinguishes you from the crowd; Stand out from the crowd and show that you're not just qualified, but capable. Our platform provides you with a chance to explore the array of opportunities that are publicised through Glosity  in the areas of cutting-edge technologies"
          subtitleLineBreak="Seize the opportunity to learn and grow"
          buttontext="Explore Opportunities"
        />
      </div>
    </div>
  );
};

export default CompanySection;
