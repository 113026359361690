import { compose, combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Recruiter from "./Reducers/recruiterOnboarding";
import RootSaga from "../redux/Sagas/rootSaga";
import { authReducer } from "./Reducers/StudentOnboarding/auth";
import ExploreCardReducer from "./Reducers/ExploreCareer";
import modalReducer from "./Reducers/Modal/modal";
import RoadMapReducer from "./Reducers/RoadMap";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "navigation"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  RecruiterOnboard: Recruiter,
  ExploreCards: ExploreCardReducer,
  RoadMap: RoadMapReducer,
  modal: modalReducer,
});

const persistRootReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagamiddleware = createSagaMiddleware();

// const middlewares = [sagamiddleware]

export const store = createStore(
  persistRootReducer,
  composeEnhancers(applyMiddleware(sagamiddleware))
);

sagamiddleware.run(RootSaga);

export const persistedStore = persistStore(store);
