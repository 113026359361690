import { SET_DATA } from "../../Actions/RoadMap";

const initialState = {
  modalData: null,
};

const RoadMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return { ...state, modalData: action.payload };
    default:
      return state;
  }
};

export default RoadMapReducer;
