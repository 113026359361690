import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_NESTED_MODAL,
  CLOSE_NESTED_MODAL,
} from "../../Actions/Modal/modal";

const initialstate = {
  type: "",
  modal: false,
  nestedModalType: "",
  nestedModal: false,
  data: "",
  nestedModalData: "",
};

export const modalReducer = (state = initialstate, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        type: action.payload.type,
        modal: action.payload.open,
        data: action.payload.data,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        type: action.payload.type,
        modal: action.payload.close,
      };
    case OPEN_NESTED_MODAL:
      return {
        ...state,
        nestedModalType: action.payload.type,
        nestedModal: action.payload.open,
        nestedModalData: action.payload.data,
      };
    case CLOSE_NESTED_MODAL:
      return {
        ...state,
        nestedModalType: action.payload.type,
        nestedModal: action.payload.close,
      };
    default:
      return state;
  }
};

export default modalReducer;
