import React from "react";
import { useStyles } from "./Styles";
import Review from "./Review";
import Image from "../../../Assets/images/LandingPage/Review/image.png";
import { ReactComponent as Quote } from "../../../Assets/images/LandingPage/Review/quote.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { pxToVh } from "../../../Utils/pxToRem";

const ReviewMain = () => {
  const classes = useStyles();

  var settings = {
    dots: true,
    // autoplay:true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    // autoplaySpeed: 3500,
    // cssEase: "linear",
    pauseOnHover: true,
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          paddingBottom: pxToVh(50),
        }}
      >
        <ul style={{ color: "#0EC99E", size: "50px", margin: "0px" }}>
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
  };

  return (
    <div className={classes.reviewMain}>
      <Quote className={classes.quote} />
      <div className={classes.headerWrapper}>
        <h1 className={classes.header}>Get a job, that propels your passion</h1>
      </div>
      <div className={classes.reviewCarousalDesktop}>
        <Slider {...settings} className={classes.carousal}>
          <Review
            // title="Revolutionized product"
            review="Glosity propels you into the career orbit, through your proof of work that distinguishes you from the crowd; Stand out from the crowd and show that you're not just qualified, but capable. Our platform provides you with an opportunity to explore the array of jobs that are publicised through Glosity  in the areas of cutting-edge technologies"
            image={Image}
            name="Job hunt ends here; just tap into the opportunity board."
            // designation="Co Founder,CEO"
          />
          {/* <Review
            title="Game Changer"
            review="The accuracy and efficiency of this product have been a game changer for our company. It has helped us find the right fit for our team, leading to increased productivity and job satisfaction."
            image={Image}
            name="Dr.Suman Bhatacharya"
            designation="Head of Technologies"
          /> */}
          {/* <Review
            title="Incredible Experience"
            review="The accuracy and efficiency of this product have been a game changer for our company. It has helped us find the right fit for our team, leading to increased productivity and job satisfaction."
            image={Image}
          /> */}
        </Slider>
        {/* <Carousel
          className={classes.carousalDesktop}
          showArrows={false}
          showIndicators={true}
          showStatus={false}
          interval="1500"
          transitionTime="2000"
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.dots}
                  style={{
                    background: "#0EC99E",
                  }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                className={classes.dots}
                style={{
                  background: "#0EC99E30",
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
        </Carousel> */}
      </div>
      {/* <div className={classes.reviewCarousal}>
        <Carousel
          className={classes.carousal}
          showArrows={false}
          showIndicators={true}
          showStatus={false}
          autoPlay
          infiniteLoop={true}
          interval="15000"
          transitionTime="5000"
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.dots}
                  style={{
                    background: "#0EC99E",
                  }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                className={classes.dots}
                style={{
                  background: "#0EC99E30",
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
        </Carousel>
      </div>  */}
    </div>
  );
};

export default ReviewMain;
