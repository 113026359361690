export const SET_DATA = "setData";
export const SET_SELECTED_REEL = "setSelectedReel";

export const setData = (data) => {
  return { type: SET_DATA, payload: data };
};
export const setSelectedReel = (data) => {
  return { type: SET_SELECTED_REEL, payload: data };
};

export const Like = (data) => {
  return {
    type: Like,
    payload: data,
  };
};

export const Dislike = (data) => {
  return {
    type: Dislike,
    payload: data,
  };
};
