import React from "react";
import { useStyles } from "./Styles";

const Title = () => {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <h1 className={classes.text}>Get Rewarded for Skills, Not Pedigree.</h1>
      <p className={classes.subtext}>
        Glosity is a career hub for early career professionals to nurture their
        aspirations. It helps ECPs to explore interesting job opportunities,
        seek insights from industry leaders, and land the perfect role with
        credible proof of work.
      </p>
    </div>
  );
};

export default Title;
